.app {
  min-height: 100vh;
}

.app.overlay::after {
  background: rgba(0, 0, 0, 0.5);
  content: "";
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 10;
}

@media screen and (min-width: 770px) {
  .app main {
    display: block;
    width: 770px;
  }
}

@media screen and (min-width: 1200px) {
  .app {
    min-height: 100vh;
    overflow: auto;
  }

  .app > div:first-child {
    margin-right: 390px;
  }

  .app aside {
    background: #f8fafb;
    overflow: hidden;
    overflow: hidden;
    width: 390px;
    position: fixed;
    right: 0;
    height: 100vh;
  }
}

.rounded {
  border-radius: 3px;
}
