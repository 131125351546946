.welcome {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcome__email,
.welcome__text {
  opacity: 0;
  position: relative;
  transform: translateY(20px);
  animation-fill-mode: forwards;
  animation-name: animatetop;
  animation-duration: 400ms;
}

.welcome__email {
  animation-delay: 200ms;
}

@keyframes animatetop {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
