.icon-balance-error {
  position: absolute;
  left: 50%;
  top: 47%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: white;
  transform: translate(-50%,-50%);
}

.icon-balance-error::after,
.icon-balance-error::before {
  background: #868686;
  border-radius: 2px;
  content: '';
  position: absolute;
  height: 2px;
  width: 30px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.icon-balance-error::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
