.sidebar-table {
  border-collapse: collapse;
  margin: 0.5rem 0 1rem;
  width: 100%;
}

.sidebar-table td {
  font: 300 0.9rem/1.2rem 'Roboto', serif;
  color: #868686;
  padding: 0.8rem 0;
  vertical-align: top;
}

.sidebar-table td:first-child {
  padding-right: 1rem;
  width: 100%;
}

.sidebar-table h4 {
  font-weight: normal;
}

.sidebar-table__shipping {
  border-top: 1px solid #e1e1e1;
}

.sidebar-total {
  background: #f8fafb;
  border-top: 1px solid #e1e1e1;
}

.sidebar-email {
  min-height: 50px;
}

.multiple__content {
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 5px;
  display: flex;
}

.multiple__content::-webkit-scrollbar {
  width: 5px;
}

.multiple__content::-webkit-scrollbar-track {
  background: none;
}

.multiple__content::-webkit-scrollbar-thumb {
  background: none;
  border-radius: 3px;
}

.multiple__content:hover::-webkit-scrollbar-thumb {
  background: #868686;
}

@media (min-width: 992px) {
  .sidebar-total {
    position: sticky;
    bottom: 0;
  }

  .multiple {
    height: calc(100vh - 8rem);
  }
}
