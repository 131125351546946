.cart {
  background: #fff;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 11;
  padding: 0.3rem;
  line-height: 0;
}

.cart__detail {
  background: #fff;
  border-radius: 3px;
  line-height: 1;
  padding: 1rem;
  position: absolute;
  right: 0;
  top: calc(100% + 1rem);
  width: calc(100vw - 3rem);
}

@media screen and (min-width: 992px) {
  .cart__detail {
    width: calc(100vw - 5rem);
  }
}

.cart__detail::before {
  content: '';
  border-bottom: 8px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  top: -8px;
  right: 12px;
}

.cart__table {
  color: #868686;
  text-align: left;
  margin-bottom: 2rem;
}

.cart__table th {
  font-weight: 400;
  padding: 0.5rem 0;
}

.cart__table th:last-child {
  padding-left: 1rem;
}

.cart__table td {
  font-weight: 300;
  line-height: 1.2;
  padding: 0;
  vertical-align: top;
}

.cart__table td:last-child {
  color: #468214;
  padding-left: 1rem;
  white-space: nowrap;
}
